<template>
  <div class="case-study-tile-slider">
    <T3HtmlParser
      v-if="sliderHeading"
      tag="h2"
      class="case-study-tile-slider__heading heading-3"
      :content="sliderHeading"
    />

    <ClientOnly>
      <div
        v-if="clientList.length"
        class="case-study-tile-slider__wrapper"
      >
        <button
          ref="next"
          class="swiper-button swiper-button-next"
        />

        <UiSwiper
          :options="swiperOptions"
          class="case-study-tile-slider__carousel"
        >
          <SwiperSlide
            v-for="client in clientList"
            :key="client.id"
            v-slot="state"
            class="case-study-tile-slider__item-slide"
          >
            <CaseStudyTile
              class="case-study-tile-slider__item"
              v-bind="client"
              :lang="lang"
              :focusable="state.isActive || state.isNext"
            />
          </SwiperSlide>
        </UiSwiper>

        <button
          ref="prev"
          class="swiper-button swiper-button-prev"
        />

        <div
          ref="pagination"
          class="swiper-pagination"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import { UiSwiper } from '~ui/components'
import { getValidLink } from '~ui/helpers/linkUtils'
import type { UiCeCategoryBoxesItemProps, UiCeCategoryBoxesProps } from '~ui/types'

import CaseStudyTile from '../CaseStudyTileComponents/CaseStudyTile.vue'

const props = defineProps<UiCeCategoryBoxesProps>()
const sliderSize = 6

const defaultSliderHeading = {
  en: 'More of our work',
  pl: 'Sprawdź inne nasze wdrożenia',
  de: 'Unsere anderen Projekte'
} as const

const sliderHeading = computed(() => {
  return (
    props.pageData?.badge?.header
    || defaultSliderHeading[props.lang as keyof typeof defaultSliderHeading]
  )
})

const getRandomClientArray = (
  arr: Array<UiCeCategoryBoxesItemProps>,
  size: number
) => {
  const randomClientArray = []

  for (let i = 0; i < Math.abs(size); i++) {
    const randomIndex = Math.floor(Math.random() * arr.length)
    randomClientArray.push(...arr.splice(randomIndex, 1))
  }

  return randomClientArray
}

const clientList = computed(() => {
  const currentLocaleObj = props.pageData?.i18n?.find(
    (locale) => locale?.active
  )

  const otherClientArray = props.caseStudyList.filter(
    (project) => getValidLink(project?.url) !== currentLocaleObj?.link
  )

  const isSingleTag = props.pageTagList.length === 1

  if (!props.pageTagList.length) {
    return getRandomClientArray(otherClientArray, sliderSize)
  }

  const allowedClientArray = otherClientArray.filter(({ categories }) => {
    const projectCategory = categories.map(({ category }) => category?.name)

    return props.pageTagList.some((element) =>
      projectCategory.includes(element)
    )
  })

  const clientArray = allowedClientArray.sort((a, b) => {
    const numberCompatibleCategoriesA = a.categories
      .map(({ category }) => category?.name)
      .filter((category) => props.pageTagList.includes(category)).length
    const numberCompatibleCategoriesB = b.categories
      .map(({ category }) => category?.name)
      .filter((category) => props.pageTagList.includes(category)).length

    return numberCompatibleCategoriesB - numberCompatibleCategoriesA
  })

  if (clientArray.length < sliderSize && !isSingleTag) {
    const clientsIdsArray = clientArray.map((client) => client.id)
    const unrelatedClientArray = otherClientArray.filter(
      (client) => !clientsIdsArray.includes(client.id)
    )

    return [
      ...clientArray,
      ...getRandomClientArray(
        unrelatedClientArray,
        clientArray.length - sliderSize
      )
    ]
  }

  return isSingleTag ? clientArray : clientArray.slice(0, sliderSize)
})

const pagination = ref(null)
const next = ref(null)
const prev = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation, Pagination],
    breakpoints: {
      762: { slidesPerView: 2 }
    },
    slidesPerView: 1,
    pagination: {
      el: pagination.value,
      type: 'bullets',
      clickable: true,
      bulletElement: 'button'
    },
    navigation: {
      nextEl: next.value,
      prevEl: prev.value
    }
  })
)
</script>

<style lang="scss">
.case-study-tile-slider {
  &__heading {
    margin-bottom: 3rem;
    text-align: center;
  }

  &__wrapper {
    position: relative;
  }

  &__carousel {
    list-style: none;
    padding-bottom: rem(48px);
  }

  &__item h3 {
    font-size: rem(20px);
  }

  & .swiper-button {
    position: absolute;
    top: calc(50% - 90px);
    height: rem(48px);
    width: rem(48px);
    display: none;

    --arrow-spacing: -80px;

    @include media-query(md) {
      display: block;
    }

    &::after {
      transition: filter 0.3s ease-in-out;
      filter: invert(48%) sepia(98%) saturate(1638%) hue-rotate(169deg)
        brightness(97%) contrast(91%);
    }

    &:not(.swiper-button-disabled):hover::after {
      filter: invert(71%) sepia(26%) saturate(1815%) hue-rotate(170deg)
        brightness(100%) contrast(91%);
    }

    &-prev {
      left: var(--arrow-spacing);
    }

    &-next {
      right: var(--arrow-spacing);
    }
  }

  & .swiper-pagination {
    bottom: rem(40px);
    left: 50%;
    translate: -50%;
  }
}
</style>
